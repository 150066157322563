<template>
    <v-container fluid>
        <v-card class="rounded-lg px-5">
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="register.teamName"
                        color="orange"
                        label="Όνομα ομάδας"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="register.telephone"
                        types="number"
                        color="orange"
                        label="Τηλέφωνο ομάδας"
                    />
                </v-col>
                <v-col cols="12">
                    <v-btn
                        outlined
                        color="orange"
                        class="rounded-lg secondary-font"
                        style="text-transform: capitalize;"
                        @click="register.players.push({
                            name: null,
                            surName: null,
                            residence: null,
                            mobile: null,
                            height: null,
                            jersey: null,
                            position: null,
                            birthday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                        })"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Προσθήκη παίχτη
                    </v-btn>
                </v-col>
                <v-col v-for="(item,i) in register.players.length" :key="i" cols="12">
                    <v-card class="py-5 px-5" outlined color="gray">
                        <v-btn
                            outlined
                            class="rounded-lg secondary-font"
                            style="text-transform: capitalize;"
                            @click="register.players.splice(i, 1)"
                        >
                            <v-icon>mdi-trash-can</v-icon> Διαγραφή παίχτη
                        </v-btn>
                        <v-text-field
                            v-model="register.players[i].name"
                            color="orange"
                            label="Όνομα"
                        />
                        <v-text-field
                            v-model="register.players[i].surname"
                            color="orange"
                            label="Επώνυμο"
                        />
                        <div class="text-start">
                            Ημερομηνία γέννησης
                        </div>
                        <v-date-picker v-model="register.players[i].birthday" />
                        <v-text-field
                            v-model="register.players[i].residence"
                            label="Κατοικία"
                            :clearable="clearable"
                        />
                        <v-text-field
                            v-model="register.players[i].mobile"
                            label="Κινητό"
                            maxlength="10"
                            :clearable="clearable"
                        />
                        <v-text-field
                            v-model="register.players[i].height"
                            label="Ύψος"
                            :clearable="clearable"
                        />
                        <v-text-field
                            v-model="register.players[i].jersey"
                            label="Αριθμός φανέλας"
                            type="number"
                            :clearable="clearable"
                        />
                        <v-select
                            v-model="register.players[i].position"
                            :items="positions"
                            menu-props="auto"
                            label="Θέση"
                            hide-details
                            :clearable="!readOnly"
                        />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" align="center">
                    <v-checkbox v-model="terms" color="orange">
                        <template v-slot:label>
                            <div class="sencondary-font black--text">Αποδοχή των κανονισμών του τουρνουά</div>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn :disabled="!terms" large class="custom-orange white--text rounded-lg secondary-font" style="text-transform: capitalize;" @click="registerTeam()">
                        Εγγραφή
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <custom-dialog :dialog="showDialog" :title="title" :message="message" @update="closeDialog" />
    </v-container>
</template>

<script>
    import teamService from '@/services/team'
    import CustomDialog from '@/components/Dialog'
    export default {
        components: { CustomDialog },
        data() {
            return {
                register: { teamLogo: null, players: [] },
                terms: false,
                showDialog: false,
                message: '',
                title: '',
                positions: ['guard', 'forward', 'center']
            }
        },
        methods: {
            registerTeam() {
                teamService.register(this.register).then(() => {
                    this.showDialog = true
                    this.message = 'Καλή τύχη στο νέο πρωτάθλημα!'
                    this.title = 'Η εγγραφή σας ήταν επιτυχής!'
                    this.register = { teamLogo: null, players: [] }
                }).catch(() => {
                    this.showDialog = true
                    this.message = 'Το συγκεκριμένο όνομα ομάδας χρησιμοποιείται ήδη!'
                    this.title = 'Η εγγραφή σας ήταν ανεπιτυχής!'
                })
            },
            closeDialog() {
                this.showDialog = false
                this.terms = false
                this.register = { teamLogo: null }
                this.message = ''
                this.title = ''
            }
        }
    }
</script>
